import React from "react";
import Navbar from "../global-components/navbar";
import CallToActionV1 from "../section-components/call-to-action-v1";
import Footer_v1 from "../global-components/footer";
import WhydubaiPage from "./whydubaipage";

const Whydubai = () => {
  return (
    <div>
      <Navbar />
      <WhydubaiPage />
      <CallToActionV1 />
      <Footer_v1 />
    </div>
  );
};

export default Whydubai;
