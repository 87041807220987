import React from 'react';
import Navbar from './global-components/navbar';
import AboutV4 from './section-components/about-v4';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import ServiceV5 from './section-components/service-v1';
import FaqV1 from './section-components/faq-v1';

const About_v1 = () => {
    return <div>
        <Navbar />
        <AboutV4 />
        <ServiceV5/>
        <FaqV1/>
        <CallToActionV1 />
        <Footer />
    </div>
}

export default About_v1

