import React from "react";
import Navbar from "../global-components/navbar";
import CallToActionV1 from '../section-components/call-to-action-v1';
import Footer_v1 from "../global-components/footer";
import AboutV5 from "../section-components/about-v5";

const About_v1 = () => {
  return (
    <div>
      <Navbar />
      <AboutV5 />
      <CallToActionV1 />
      <Footer_v1 />
    </div>
  );
};

export default About_v1;
