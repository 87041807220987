import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ServiceV5 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__service-area section-bg-1 pt-115 pb-70 go-top">
				<div className="container">
				<div className="row">
					<div className="col-lg-12">
					<div className="section-title-area ltn__section-title-2--- text-center">
						<h1 className="section-title">Our Core </h1>
					</div>
					</div>
				</div>
				<div className="row  justify-content-center">
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
						<span><i className="flaticon-house" /></span>
						</div>
						<div className="ltn__feature-info">
						<h3>Our Vision</h3>
						<p>At URA Homes, our vision is to meet the real estate needs and investment aspirations of individuals worldwide by connecting them with trusted real estate developers and agents. We strive to empower potential buyers to make informed decisions that enhance their investment value by providing comprehensive guidance on all aspects of the property market. We leverage our extensive expertise, industry knowledge, and real estate experience to achieve this goal.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
						<span><i className="flaticon-mortgage" /></span>
						</div>
						<div className="ltn__feature-info">
						<h3>Our Mission</h3>
						<p>URA Homes' mission is to offer a comprehensive solutions platform for real estate developers across the UAE, ensuring exceptional levels of quality customer service. We aim to make the UAE real estate market accessible to everyone. Our team comprises consummate professionals with extensive experience in relevant fields, and they represent our company's hallmark of excellence. We consider their collective years of work experience as an invaluable asset that allows us to maintain our promise of ethical and exceptional service
.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
						<span><i className="flaticon-operator" /></span>
						</div>
						<div className="ltn__feature-info">
						<h3>Objective</h3>
						<p>At URA Homes, our objective is to provide trusted real estate developers and agents with genuine real estate investment leads via our platform, which includes customized solutions for residential, commercial, retail, and land sectors. We prioritize integrity, clarity, promptness, and a commitment to delivering high-quality leads to developers and quality projects to buyers. We strive tirelessly to achieve a mutually beneficial outcome for all parties involved.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
						<span><i className="flaticon-house-1" /></span>
						</div>
						<div className="ltn__feature-info">
						<h3>Principles</h3>
						<p>At URA Homes, we are committed to providing a seamless and stress-free experience for our clients when it comes to purchasing properties in the UAE. We accomplish this by delivering genuine leads and projects to developers and buyers, providing comprehensive guidance and expert advice throughout the entire process, from selecting the perfect property to handling financial procedures and documentation. Our unwavering principles of integrity and transparency ensure that our clients receive exceptional service and complete satisfaction, resulting in repeat business and referrals from happy customers. We are proud of our reputation as an ethical organization and our ability to connect buyers with the most trusted developers in the UAE through our one-stop platform.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
						<span><i className="flaticon-house-3" /></span>
						</div>
						<div className="ltn__feature-info">
						<h3>Home Selling</h3>
						<p>over 1 million+ homes for sale available on the website, we can match you with a house you will want to call home.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
						<span><i className="flaticon-official-documents" /></span>
						</div>
						<div className="ltn__feature-info">
						<h3>Escrow Services</h3>
						<p>over 1 million+ homes for sale available on the website, we can match you with a house you will want to call home.</p>
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>
        }
}

export default ServiceV5